import {
  ApiPaginatedResponse,
  ApiQueryOptions,
  Attachment,
  CustomFilters,
  Iso639Code,
  UUID,
} from "@/types/types";
import { ApiQueryKey } from "@/configs/apiQueryKey";
import { omit, omitBy, pick, trim } from "lodash";
import { QueryFunctionContext } from "@tanstack/react-query";
import APP_CONFIG from "@/configs/appConfig";
import EntityApi from "./EntityApi";
import AppApi from "./AppApi";
import httpClient from "./httpClient";

export enum ContentType {
  TEXT,
  IMAGE,
  ELEMENT,
}

type ContentImage = Pick<Attachment, "id" | "path">;

export interface Content {
  id: UUID;
  slug: string;
  type: ContentType;
  content: string;
  image?: ContentImage;
  translations: Partial<
    Record<
      Iso639Code,
      {
        content: string;
        image?: ContentImage;
      }
    >
  >;
}

class ContentApi extends EntityApi<Content> {
  constructor() {
    super(ApiQueryKey.content);
    this.updateContent = this.updateContent.bind(this);
  }

  override async getAll({
    queryKey,
  }: QueryFunctionContext<
    [
      ApiQueryKey,
      Partial<Pick<ApiQueryOptions<Content>, "search" | "sortBy">>?,
      CustomFilters?
    ]
  >) {
    const params = omitBy(
      {
        search: trim(queryKey[1]?.search),
        sortBy: Object.values(queryKey[1]?.sortBy || {}).join(":"),
        limit: 0,
        ...queryKey[2],
      },
      (value) => value === ""
    );

    const response = await httpClient.get<ApiPaginatedResponse<Content>>(
      queryKey[0],
      {
        params,
      }
    );

    return response.data.data;
  }

  async updateContent({
    content,
    language,
  }: {
    content: any;
    language: Iso639Code;
  }) {
    const isDefault = language === APP_CONFIG.DEFAULT_LANGUAGE;
    const chosenLanguageTranslation = content.translations[language];

    if (chosenLanguageTranslation && chosenLanguageTranslation.image) {
      const attachment = await AppApi.createAttachment(
        chosenLanguageTranslation.image
      );

      const payload = isDefault
        ? {
            image: attachment.id,
          }
        : {
            translations: {
              ...content.translations,
              [language]: {
                content: "",
                image: pick(attachment, ["id", "path"]),
              },
            },
          };

      await httpClient.patch(`${this.queryKey}/${content.id}`, payload);

      return;
    }

    const payload = isDefault
      ? {
          content: content.translations[language]?.content || "",
        }
      : omit(pick(content, "translations"), APP_CONFIG.DEFAULT_LANGUAGE);

    await httpClient.patch(`${this.queryKey}/${content.id}`, payload);
  }
}

export default new ContentApi();
