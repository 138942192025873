import { Role } from '@/api/RoleApi';
import { UUID } from './types';

export enum UserStatus {
	INACTIVE,
	ACTIVE,
	SUSPENDED,
}

export enum UserType {
	USER = 'user',
	ADMIN = 'admin',
}

interface BaseUser {
	id: UUID;
	email: string;
	password: string;
	status: UserStatus;
	userType: UserType;
	createdAt: string;
}

export interface User extends BaseUser {
	name: string;
	note: string;
	maxDiscount: number;
	isWorker: boolean;
	userType: UserType.USER;
}

export interface Admin extends BaseUser {
	role: Role;
	userType: UserType.ADMIN;
}

export type AppUser = User | Admin;

export function isAdmin(user: AppUser): user is Admin {
	return user?.userType === UserType.ADMIN;
}
