import { HttpStatusCode } from 'axios';

export interface ApiError {
	error: string;
	message: ErrorCode | ErrorCode[] | string | string[];
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
}

export enum ErrorCode {
	GENERIC = 'genericError',
	INVALID_LOGIN_DATA = 'invalidLoginData',
	INVALID_FILE_TYPE = 'cantUploadFileType',
	USER_NOT_FOUND = 'userNotFound',
	USER_NOT_ACTIVE = 'userNotActive',
	INVALID_EMAIL = 'emailIsNotValid',
	DUPLICATED_EMAIL = 'duplicatedEmail',
	MISSING_FIELD = 'missingField',
	PASSWORDS_NOT_SAME = 'passwordsNotSame',
	PASSWORD_TO_WEAK = 'passwordToWeak',
	INVALID_PASSWORD = 'invalidPassword',
	INVALID_REFRESH_TOKEN = 'invalidRefreshToken',
	REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
	INVALID_TOKEN = 'invalidToken',
	INVALID_HASH = 'invalidHash',
	INVALID_POSTAL_CODE = 'invalidPostalCode',
	PRODUCT_CANNOT_BE_UPDATED = 'productCannotBeUpdated',
	DUPLICATED_NAME = 'duplicatedName',
	TOO_MANY_REQUESTS = 'tooManyRequests',
	DUPLICATED_NUMBER = 'duplicatedNumber',
}
