export enum AppPaths {
  LOGIN = "login",
  RESET_PASSWORD = "reset-password",
  SET_PASSWORD = "set-password",

  USERS = "users",
  CLIENTS = "clients",
  ADMINS = "admins",
  ROLES = "roles",

  OFFERS = "offers",
  COVERS = "covers",
  DOCUMENTS = "documents",
}
