const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	HEADER_HEIGHT: 70,
	TABLE_PAGE_SIZES: [15, 50, 100],
	DEFAULT_LANGUAGE: 'pl',
	DEFAULT_ISO_CODE: 'pl',
	DEFAULT_DOCUMENT_TITLE: 'Kreator ofert',
	ADMIN_DEFAULT_ROLE_ID: '67a2bc32-3055-400f-a919-76a7e0813c65',
	DESCRIPTION_MAX_LENGTH: 1000,
	MIN_PIRCE: 0,
	MAX_PRICE: 1_000_000,
	MAX_PRICE_INCREASE_PERCENT: 100,
	DEFAULT_OFFER_DESCRIPTION: '',
	SESSION_STORAGE_KEYS: {
		REFRESH_TOKEN: 'refreshToken',
		USERS: {
			PAGE: 'users-page',
			LIMIT: 'users-limit',
			SEARCH: 'users-search',
			SORT: 'users-sort',
		},
		CLIENTS: {
			PAGE: 'clients-page',
			LIMIT: 'clients-limit',
			SEARCH: 'clients-search',
			SORT: 'clients-sort',
		},
		OFFERS: {
			PAGE: 'offers-page',
			LIMIT: 'offers-limit',
			SEARCH: 'offers-search',
			SORT: 'offers-sort',
		},
		COVERS: {
			PAGE: 'covers-page',
			LIMIT: 'covers-limit',
			SEARCH: 'covers-search',
			SORT: 'covers-sort',
		},
	},
	ISO_639: [
		'ad',
		'al',
		'am',
		'at',
		'az',
		'ba',
		'be',
		'bg',
		'by',
		'ch',
		'cy',
		'cz',
		'de',
		'dk',
		'ee',
		'es',
		'fi',
		'fr',
		'gb',
		'ge',
		'gr',
		'hr',
		'hu',
		'ie',
		'is',
		'it',
		'kz',
		'li',
		'lt',
		'lu',
		'lv',
		'mc',
		'md',
		'me',
		'mk',
		'mt',
		'nl',
		'no',
		'pl',
		'pt',
		'ro',
		'rs',
		'ru',
		'se',
		'si',
		'sk',
		'sm',
		'tr',
		'ua',
		'us',
	],
} as const;

export default APP_CONFIG;
