import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from './MantineProvider';
import { QueryProvider } from './QueryProvider';
import { AuthProvider } from './AuthProvider';
import { ContentProvider } from './ContentProvider';
import { Loading } from '@/components/ui/Loading';
import { store } from '@/store';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import 'moment/dist/locale/pl';

moment.locale(APP_CONFIG.DEFAULT_LANGUAGE);

export const AppProviders = ({ children }: { children: ReactNode }) => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<MantineProvider>
					<QueryProvider>
						<AuthProvider>
							<ContentProvider>
								<Suspense fallback={<Loading />}>{children}</Suspense>
							</ContentProvider>
						</AuthProvider>
					</QueryProvider>
				</MantineProvider>
			</BrowserRouter>
		</Provider>
	);
};
