import { ReactNode } from 'react';
import {
	Avatar,
	Loader,
	LoadingOverlay,
	MantineProvider as Mantine,
	Pagination,
	Popover,
	RangeSlider,
	ScrollArea,
	Select,
	MultiSelect,
	Slider,
	createTheme,
	Tooltip,
	NumberInput,
	FileInput,
	Input,
} from '@mantine/core';
import { SpinnerLoader } from '@/components/ui/Loading';
import { DatePickerInput, DatesProvider, MonthPicker } from '@mantine/dates';
import { RichTextEditor } from '@mantine/tiptap';
import mantineCustomStyles from '@/assets/scss/custom/mantine.module.scss';
import classNames from 'classnames';
import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/dropzone/styles.layer.css';
import '@mantine/tiptap/styles.layer.css';
import 'dayjs/locale/pl';

const theme = createTheme({
	fontFamily: 'Poppins, sans-serif',
	components: {
		JsonInput: {
			classNames: {
				input: mantineCustomStyles.jsonInputInput,
			},
		},
		Tooltip: Tooltip.extend({
			defaultProps: {
				withArrow: true,
			},
			classNames: {
				tooltip: 'tooltip',
			},
		}),
		Popover: Popover.extend({
			defaultProps: {
				position: 'bottom-end',
				shadow: 'md',
				radius: '.4rem',
			},
			classNames: {
				dropdown: `${mantineCustomStyles.popoverDropdown} popover`,
			},
		}),
		Select: Select.extend({
			classNames: {
				input: 'form-control',
				dropdown: mantineCustomStyles.selectDropdown,
				options: mantineCustomStyles.selectItemsOptions,
				option: mantineCustomStyles.selectOption,
				empty: mantineCustomStyles.selectNothingEmpty,
			},
			defaultProps: {
				rightSectionWidth: 30,
				radius: 4,
				allowDeselect: false,
				withCheckIcon: false,
				comboboxProps: {
					zIndex: 1100,
				},
			},
		}),
		MultiSelect: MultiSelect.extend({
			classNames: {
				empty: mantineCustomStyles.selectNothingEmpty,
				dropdown: mantineCustomStyles.multiselectDropdown,
				input: classNames('form-control', mantineCustomStyles.multiselectInput),
				inputField: 'form-control',
				option: mantineCustomStyles.selectOption,
				section: mantineCustomStyles.multiselectSection,
			},
			defaultProps: {
				rightSectionWidth: 30,
				radius: 4,
				withCheckIcon: false,
				hidePickedOptions: true,
			},
		}),
		Loader: Loader.extend({
			defaultProps: {
				loaders: { ...Loader.defaultLoaders, spinner: SpinnerLoader },
				type: 'spinner',
			},
		}),
		LoadingOverlay: LoadingOverlay.extend({
			defaultProps: {
				loaderProps: {
					type: 'spinner',
				},
			},
		}),
		Slider: Slider.extend({
			classNames: {
				markLabel: mantineCustomStyles.sliderMarkLabel,
			},
			defaultProps: {
				color: '#2563eb',
				labelTransitionProps: { transition: 'fade', duration: 250 },
			},
		}),
		RangeSlider: RangeSlider.extend({
			defaultProps: {
				color: '#2563eb',
				labelTransitionProps: { transition: 'fade', duration: 250 },
				minRange: 1,
			},
		}),
		Avatar: Avatar.extend({
			classNames: {
				placeholder: mantineCustomStyles.avatarPlaceholder,
			},
		}),
		Pagination: Pagination.extend({
			classNames: {
				control: mantineCustomStyles.paginationControl,
				dots: mantineCustomStyles.paginationDots,
			},
			defaultProps: {
				gap: 0,
			},
		}),
		MonthPicker: MonthPicker.extend({
			defaultProps: {
				withCellSpacing: false,
				size: 'xs',
			},
			classNames: {
				calendarHeader: mantineCustomStyles.monthPickerHeader,
				monthsListCell: mantineCustomStyles.monthPickerMonthsListCell,
			},
		}),
		ScrollArea: ScrollArea.extend({
			defaultProps: {
				scrollbarSize: 4,
			},
		}),
		RichTextEditor: RichTextEditor.extend({
			classNames: {
				root: mantineCustomStyles.richTextEditorRoot,
				toolbar: mantineCustomStyles.richTextEditorToolbar,
				controlsGroup: mantineCustomStyles.richTextEditorControlsGroup,
				control: mantineCustomStyles.richTextEditorControl,
				content: mantineCustomStyles.richTextEditorContent,
				linkEditorInput: classNames(
					'form-control',
					mantineCustomStyles.richTextEditorLinkEditorInput
				),
				linkEditorSave: classNames(
					'btn btn-secondary',
					mantineCustomStyles.richTextEditorLinkEditorSave
				),
			},
		}),
		DatePickerInput: DatePickerInput.extend({
			defaultProps: {},
			classNames: {
				input: 'form-control',
				levelsGroup: mantineCustomStyles.datePickerLevelsGroup,
				monthCell: mantineCustomStyles.datePickerMonthCell,
			},
		}),
		NumberInput: NumberInput.extend({
			defaultProps: {
				clampBehavior: 'strict',
				decimalSeparator: ',',
				decimalScale: 2,
			},
		}),
		Input: Input.extend({
			classNames: {
				input: 'form-control',
			},
		}),
		FileInput: FileInput.extend({
			classNames: {
				input: mantineCustomStyles.fileInputInput,
			},
		}),
	},
});

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	return (
		<Mantine theme={theme}>
			<DatesProvider settings={{ locale: 'pl' }}>{children}</DatesProvider>
		</Mantine>
	);
};
