import { ApiQueryKey } from '@/configs/apiQueryKey';
import { Admin, User } from '@/types/users';
import { Attachment, UUID } from '@/types/types';
import { Client } from './ClientApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { fileTypeToHeader } from '@/utils/utilities';
import EntityApi, { BaseEntity } from './EntityApi';
import httpClient from './httpClient';

export enum SyncTypeEnum {
	PRODUCT = 'product',
	CATEGORY = 'category',
	ALL = 'all',
}

export enum OfferType {
	OFFER,
	ORDER,
}

export interface Product {
	createdAt: string;
	description: string;
	externalId: number;
	id: UUID;
	image?: Attachment;
	name: string;
	price: number;
	updatedAt: string;
	quantity: number;
}

export interface OfferProduct {
	description: string;
	name: string;
	id: UUID;
	data?: OfferProduct;
	price?: number;
	createAt: string;
	product: Product;
	quantity: number;
}

export interface Offer {
	id: UUID;
	offerNumber: string;
	user: User;
	admin?: Admin;
	description: string;
	offerProducts: OfferProduct[];
	client?: Client;
	clientName?: string;
	price: number;
	discount: number;
	increasePercent: number;
	type: OfferType;
	createdAt: string;
	hidePrices: boolean;
}

class OfferApi extends EntityApi<Offer> {
	constructor() {
		super(ApiQueryKey.offers);
		this.exportReports = this.exportReports.bind(this);
		this.generatePdf = this.generatePdf.bind(this);
		this.fetchExternalOffer = this.fetchExternalOffer.bind(this);
	}

	// async calculatePrice({
	// 	queryKey,
	// }: QueryFunctionContext<
	// 	[
	// 		ApiQueryKey,
	// 		{
	// 			offerProducts: offerProducts[];
	// 			discount: number;
	// 			increasePercent: number;
	// 		}
	// 	]
	// >) {
	// 	const payload = {
	// 		...queryKey[1],
	// 	};

	// 	const response = await httpClient.post<{
	// 		basePrice: number;
	// 		price: number;
	// 		discountAmount: number;
	// 	}>(`${queryKey[0]}/calculate-price`, payload);

	// 	return response.data;
	// }

	async exportReports(payload: {
		user: BaseEntity;
		dateFrom: string;
		dateTo: string;
		offerType: OfferType;
	}) {
		const response = await httpClient.post<Blob>(
			`${this.queryKey}/export`,
			payload,
			{
				responseType: 'blob',
			}
		);

		return response.data;
	}

	async generatePdf(offer: Offer) {
		const response = await httpClient.get<Blob>(
			`${this.queryKey}/${offer.id}/generate-pdf`,
			{
				headers: {
					Accept: fileTypeToHeader('pdf'),
				},
				responseType: 'blob',
			}
		);

		return response.data;
	}

	async fetchExternalOffer(url: string) {
		const response = await httpClient.post<Offer>(
			`${this.queryKey}/fetch-external-offer`,
			{ url }
		);

		return response.data;
	}
}

export default new OfferApi();
