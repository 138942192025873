import { ca } from 'date-fns/locale';

export const ApiQueryKey = {
	admins: 'admins',
	users: 'users',
	clients: 'clients',
	offers: 'offers',

	content: 'content',
	documents: 'documents',
	roles: 'roles',
	markets: 'markets',
	covers: 'covers',
	product: 'product',
	category: 'category',
} as const;

export type ApiQueryKey = (typeof ApiQueryKey)[keyof typeof ApiQueryKey];
