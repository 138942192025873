import {
	useContext,
	ReactNode,
	useLayoutEffect,
	createContext,
	useState,
} from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { Admin, AppUser, UserType } from '@/types/users';
import { LoginValues, SubmitStatus } from '@/pages/unauthenticated/Login';
import { useQueryClient } from '@tanstack/react-query';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { AxiosError } from 'axios';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/httpClient';
import StorageKey from '@/configs/storageKey';
import Cookies from 'js-cookie';

interface TokenPayload extends JwtPayload {
	user: AppUser;
}

interface Context {
	remember: boolean;
	setRemember: (value: boolean) => void;
	user: AppUser | null;
	changeUser: (newUser?: AppUser | null) => void;
	login: (values: LoginValues) => Promise<number>;
	logout: () => void;
	isUserAdmin: (user: AppUser | null) => user is Admin;
	isAdmin: boolean;
	isUser: boolean;
}

const AuthContext = createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [remember, setRemember] = useLocalStorage<boolean>({
		key: StorageKey.REMEMBER,
		defaultValue: true,
		getInitialValueInEffect: false,
	});

	const [user, setUser] = useLocalStorage<AppUser | null>({
		key: StorageKey.USER,
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken();
	}, []);

	const loginWithResfreshToken = async () => {
		const refresh = Cookies.get(StorageKey.REFRESH_TOKEN);
		if (!refresh) return;

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			await setAuth(decoded, accessToken, refreshToken);
		} catch (error: any) {
			console.error(error);
			if (error.code !== AxiosError.ECONNABORTED) logout();
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken();
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async ({ email, password }) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			setAuth(decoded, accessToken, refreshToken);

			return SubmitStatus.SUCCESS;
		} catch (error) {
			console.error(error);
			return SubmitStatus.ERROR;
		}
	};

	const logout: Context['logout'] = () => {
		// UnauthenticatedApi.logout();
		queryClient.cancelQueries();
		Cookies.remove(StorageKey.REFRESH_TOKEN);
		httpClient.defaults.headers.common['Authorization'] = '';
		setUser(null);
		queryClient.clear();
		clearTimeout(refreshTimeout);
	};

	const setAuth = async (
		decoded: TokenPayload,
		accessToken: string,
		refreshToken: string
	) => {
		const { user } = decoded;

		// const appUser = await UserApi.getCurrentUser();

		setAuthHeader(accessToken);

		Cookies.set(StorageKey.REFRESH_TOKEN, refreshToken, {
			secure: true,
			expires: remember ? 360 : undefined,
		});

		refreshTokenWhenExpire(decoded);

		setUser(user);
	};

	const changeUser: Context['changeUser'] = (newUser = null) =>
		setUser(newUser);

	const isUserAdmin = (user: AppUser | null): user is Admin => {
		return user?.userType === UserType.ADMIN;
	};

	const isAdmin = isUserAdmin(user),
		isUser = user?.userType === UserType.USER;

	return (
		<AuthContext.Provider
			value={{
				remember,
				setRemember,
				user,
				changeUser,
				login,
				logout,
				isUserAdmin,
				isAdmin,
				isUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
