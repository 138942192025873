import {
	useContext,
	ReactNode,
	useEffect,
	useState,
	RefAttributes,
	createContext,
	useLayoutEffect,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDocumentTitle, useLocalStorage } from '@mantine/hooks';
import { Iso639Code, StringSignature } from '@/types/types';
import { isEmpty } from 'lodash';
import { Link, LinkProps, useSearchParams } from 'react-router-dom';
import { getFileSrc } from '@/utils/utilities';
import { Loading } from '@/components/ui/Loading';
import { useAuth } from './AuthProvider';
import { ErrorCode } from '@/types/ErrorCodes';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import ContentApi, { Content, ContentType } from '@/api/ContentApi';
import StorageKey from '@/configs/storageKey';
import httpClient from '@/api/httpClient';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/de';

const errorSlug = 'error.';

type GetElementOptions = {
	links?: StringSignature<LinkProps & RefAttributes<HTMLAnchorElement>>;
	vars?: StringSignature<string | number>;
};

export type AppContent = StringSignature<Content>;

interface Context {
	language: Iso639Code;
	setLanguage: (lang: Iso639Code) => void;
	isDefaultLanguage: boolean;
	getContent: (slug: string, options?: GetElementOptions) => string;
	getErrorMessage: (codes: ErrorCode[] | string[]) => string;
}

const ContentContext = createContext<Context>(null!);

export const useContent = () => useContext(ContentContext);

export const ContentProvider = ({ children }: { children: ReactNode }) => {
	const { isAdmin } = useAuth();

	const [content, setContent] = useLocalStorage<AppContent>({
		key: StorageKey.CONTENT,
		defaultValue: {},
		getInitialValueInEffect: false,
	});
	const contentQuery = useQuery({
		queryKey: [ContentApi.queryKey],
		queryFn: ContentApi.getAll,
		staleTime: 1000 * 60 * 5,
		select: (content) =>
			content.reduce<AppContent>((acc, curr) => {
				acc[curr.slug] = curr;
				return acc;
			}, {}),
	});

	useEffect(() => {
		if (!contentQuery.data) return;

		setContent(contentQuery.data);
	}, [contentQuery.data]);

	const [language, setLanguage] = useLocalStorage<Iso639Code>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});

	const [searchParams] = useSearchParams();

	useLayoutEffect(() => {
		const lang = searchParams.get('lang');
		if (!lang) return;

		setLanguage(lang.toLowerCase() as Iso639Code);
	}, []);

	const isDefaultLanguage = isAdmin || language === APP_CONFIG.DEFAULT_LANGUAGE;

	useDocumentTitle(
		content['document.title']?.translations[language]?.content ||
			APP_CONFIG.DEFAULT_DOCUMENT_TITLE
	);

	const [_, setRefresher] = useState(false);
	const refreshApp = () => setRefresher((prev) => !prev);

	useEffect(() => {
		moment.locale(language);
		document.documentElement.setAttribute('lang', language);
		httpClient.defaults.headers['Accept-Language'] = language;

		refreshApp();
	}, [language]);

	const checkIfExist = (slug: string) => {
		if (!content[slug]) return false;

		if (isDefaultLanguage)
			return content[slug].type === ContentType.IMAGE
				? !!content[slug].image
				: !!content[slug].content;

		if (!content[slug].translations || !content[slug].translations[language])
			return false;

		if (content[slug].type === ContentType.IMAGE)
			return !!content[slug].translations[language]?.image;

		return !!content[slug].translations[language]?.content;
	};

	const getText = (
		slug: string,
		vars: StringSignature<string | number> = {}
	) => {
		let text = isDefaultLanguage
			? content[slug].content
			: content[slug].translations[language]?.content;
		text ??= '';

		for (const [variable, value] of Object.entries(vars)) {
			text = text.replaceAll(variable, value.toString());
		}

		return text;
	};

	const getElement = (slug: string, options?: GetElementOptions) => {
		const raw = getText(slug, options?.vars);

		return parse(raw, {
			replace: (domNode) => {
				const node = domNode as Element;

				if (
					node.name === 'a' &&
					options?.links &&
					options?.links[node.attribs.href]
				) {
					return (
						<Link {...options.links[node.attribs.href]}>
							{domToReact(node.children as DOMNode[])}
						</Link>
					);
				}
			},
		});
	};

	const getImage = (slug: string) => {
		return getFileSrc(
			isDefaultLanguage
				? content[slug].image?.path
				: content[slug].translations[language]?.image?.path
		);
	};

	const getContent: Context['getContent'] = (slug, options) => {
		if (!checkIfExist(slug)) return slug;

		switch (content[slug].type) {
			case ContentType.TEXT:
				return getText(slug, options?.vars);
			case ContentType.ELEMENT:
				return getElement(slug, options) as string;
			case ContentType.IMAGE:
				return getImage(slug);
			default:
				return slug;
		}
	};

	const codeToMessage = (code: ErrorCode | string) =>
		Object.values(ErrorCode).includes(code as ErrorCode)
			? getText(`${errorSlug}${code}`)
			: '';

	const getErrorMessage: Context['getErrorMessage'] = (codes) => {
		const errorMessages = Array.isArray(codes)
			? codes.map((code) => codeToMessage(code))
			: [codeToMessage(codes)];

		const message =
			errorMessages.filter((m) => !!m).join('. ') ||
			codeToMessage(ErrorCode.GENERIC);

		return message;
	};
	return (
		<ContentContext.Provider
			value={{
				language,
				setLanguage,
				isDefaultLanguage,
				getContent,
				getErrorMessage,
			}}
		>
						{/* {isEmpty(content) ? <Loading /> : children} */}

			{children}
		</ContentContext.Provider>
	);
};
