import { flatten, pick } from 'lodash';
import { isAxiosError } from 'axios';
import { ApiError, AppError, ErrorCode } from '@/types/ErrorCodes';
import { formatDistance } from 'date-fns';
import { BaseEntity } from '@/api/EntityApi';
import { OfferType } from '@/api/OfferApi';
import { Client } from '@/api/ClientApi';
import { pl } from 'date-fns/locale';
import {
	IMAGE_MIME_TYPE,
	MS_WORD_MIME_TYPE,
	PDF_MIME_TYPE,
} from '@mantine/dropzone';
import moment from 'moment';

export const priceFormatter = new Intl.NumberFormat('PL-pl', {
	style: 'currency',
	currency: 'PLN',
	currencyDisplay: 'code',
});

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

export function randomDate(start: Date, end: Date) {
	return moment(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	).toDate();
}

export function formatDateForApi(date: moment.MomentInput) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('HH:mm');
}

export function sortByDate(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function sortByName(
	nameA = '',
	nameB = '',
	order: 'asc' | 'desc' = 'asc'
) {
	return order === 'asc'
		? nameA.localeCompare(nameB)
		: nameB.localeCompare(nameA);
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function decrementDate(date: moment.MomentInput) {
	return moment(date).subtract(1, 'day').toDate();
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export const formatPrice = (value = 0, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		...opts,
	});

	return formatter.format(value);
};

export const isAppError = (error: unknown): error is AppError => {
	return !!(error as AppError).status && !!(error as AppError).stack;
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export const fontColorForBackground = (backgroundColorHex = '#ffffff') => {
	return [
		0.299 * parseInt(backgroundColorHex.slice(1, 3), 16),
		0.587 * parseInt(backgroundColorHex.slice(3, 5), 16),
		0.114 * parseInt(backgroundColorHex.slice(5, 7), 16),
	].reduce((a, b) => a + b) /
		255 >
		0.65
		? '#495057'
		: '#ffffff';
};

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return `-`;
	return firstName || lastName;
};

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatDateFromNow(date: Date) {
	return formatDistance(date, new Date(), {
		locale: pl,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function fileSizeToMB(size: number | string) {
	return Math.round((Number(size) / 1000000 + Number.EPSILON) * 100) / 100;
}

export function extractId<TData extends BaseEntity>(entity: TData) {
	return pick(entity, 'id');
}

// export function formatParameterValueName(parameterValue) {
// 	return parameterValue.parameter.suffix
// 		? `${parameterValue.value} ${parameterValue.parameter.suffix}`
// 		: parameterValue.value;
// }

export function calculatePriceWithDiscounts(
	base: number,
	discout: number,
	increace: number
) {
	return base - (base * discout) / 100 + (base * increace) / 100;
}

export function viewClientAddress(client: Client) {
	if (!client.city && client.zipCode && !client.street) return '-';
	return `${client.city || ''}, ${client.zipCode || ''} ${client.street || ''}`;
}

// export function getProductStatusSlug(status) {
// 	switch (status) {
// 		case ProductStatus.ACTIVE:
// 			return 'productStatus.active';
// 		case ProductStatus.INACTIVE:
// 			return 'productStatus.inactive';
// 	}
// }

export function getOfferTypeSlug(type: OfferType) {
	switch (type) {
		case OfferType.OFFER:
			return 'Oferta';
		case OfferType.ORDER:
			return 'Zamówienie';
	}
}

export function getFileTypeIcon(mimeType: string) {
	if ((IMAGE_MIME_TYPE as string[]).includes(mimeType))
		return 'mdi mdi-image text-success';
	if ((PDF_MIME_TYPE as string[]).includes(mimeType))
		return 'mdi mdi-file-pdf text-danger';
	if ((MS_WORD_MIME_TYPE as string[]).includes(mimeType))
		return 'mdi mdi-microsoft-word text-primary';

	return 'mdi-file-excel-box-outline text-dark';
}

export function getEmptyPDfOptions() {
	return {
		realizationTime: 0,
		advanceAmount: 0,
		advanceAmountInWords: '',
		advanceDate: new Date().toISOString(),
		// advancePaymentType: AdvancePaymentType.NONE,
		financing: false,
		paymentMethod: [],
		otherPaymentMethod: '',
		comments: '',
		city: '',
		date: new Date().toISOString(),
	};
}

// export function mapOfferPaymentMethodName(paymentMethod: OfferPaymentMethod) {
// 	switch (paymentMethod) {
// 		case OfferPaymentMethod.FACTORING:
// 			return 'Factoring';
// 		case OfferPaymentMethod.TRANSFER:
// 			return 'Transfer 100%';
// 		case OfferPaymentMethod.TRANSFER30:
// 			return 'Transfer 30%/70%';
// 	}
// }

// export function getAdvancePaymentTypeSlug(type: AdvancePaymentType) {
// 	switch (type) {
// 		case AdvancePaymentType.NONE:
// 			return 'advancePaymentType.none';
// 		case AdvancePaymentType.CASH:
// 			return 'advancePaymentType.cash';
// 		case AdvancePaymentType.WIRE_TRANSFER:
// 			return 'advancePaymentType.wireTransfer';
// 		case AdvancePaymentType.PAID:
// 			return 'advancePaymentType.paid';
// 	}
// }

// export function getPaymentMethodSlug(paymentMethod: PaymentMethod) {
// 	switch (paymentMethod) {
// 		case PaymentMethod.WIRE_TRANSFER:
// 			return 'paymentMethod.wireTransfer';
// 		case PaymentMethod.PARTNER_LOAN:
// 			return 'paymentMethod.partnerLoan';
// 		case PaymentMethod.OBLIGATION:
// 			return 'paymentMethod.obligation';
// 		case PaymentMethod.LOAN:
// 			return 'paymentMethod.loan';
// 		case PaymentMethod.OTHER:
// 			return 'paymentMethod.other';
// 	}
// }

export function formatClientLabel(client: Client) {
	if (!client.street?.trim() && !client.city?.trim()) return client.name;

	return `${client.name} | ${client.street ? ` ${client.street}` : ''}${
		client.city ? ` ${client.city}` : ''
	}`;
}
